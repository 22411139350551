$(document).ready(function(){
    $('#modal-btn-id--1.modal-btn').click(function() {
        $('#modal-id--1.modal').fadeIn('slow');
    });
    $('#modal-btn-id--2.modal-btn').click(function() {
        $('#modal-id--2.modal').fadeIn('slow');
    });
    $('#modal-btn-id--3.modal-btn').click(function() {
        $('#modal-id--3.modal').fadeIn('slow');
    });
    $('#modal-btn-id--4.modal-btn').click(function() {
        $('#modal-id--4.modal').fadeIn('slow');
    });
    $('#modal-btn-id--5.modal-btn').click(function() {
        $('#modal-id--5.modal').fadeIn('slow');
    });
    $('#modal-btn-id--6.modal-btn').click(function() {
        $('#modal-id--6.modal').fadeIn('slow');
    });
    $('#modal-btn-id--7.modal-btn').click(function() {
        $('#modal-id--7.modal').fadeIn('slow');
    });
    $('#modal-btn-id--8.modal-btn').click(function() {
        $('#modal-id--8.modal').fadeIn('slow');
    });
    $('#modal-btn-id--9.modal-btn').click(function() {
        $('#modal-id--9.modal').fadeIn('slow');
    });
    $('#modal-btn-id--10.modal-btn').click(function() {
        $('#modal-id--10.modal').fadeIn('slow');
    });
    $('#modal-btn-id--11.modal-btn').click(function() {
        $('#modal-id--11.modal').fadeIn('slow');
    });
    $('#modal-btn-id--12.modal-btn').click(function() {
        $('#modal-id--12.modal').fadeIn('slow');
    });
    $('#modal-btn-id--13.modal-btn').click(function() {
        $('#modal-id--13.modal').fadeIn('slow');
    });
    $('#modal-btn-id--14.modal-btn').click(function() {
        $('#modal-id--14.modal').fadeIn('slow');
    });
    $('#modal-btn-id--15.modal-btn').click(function() {
        $('#modal-id--15.modal').fadeIn('slow');
    });
    $('#modal-btn-id--16.modal-btn').click(function() {
        $('#modal-id--16.modal').fadeIn('slow');
    });
    $('#modal-btn-id--17.modal-btn').click(function() {
        $('#modal-id--17.modal').fadeIn('slow');
    });
    $('#modal-btn-id--18.modal-btn').click(function() {
        $('#modal-id--18.modal').fadeIn('slow');
    });
    $('#modal-btn-id--19.modal-btn').click(function() {
        $('#modal-id--19.modal').fadeIn('slow');
    });
    $('#modal-btn-id--20.modal-btn').click(function() {
        $('#modal-id--20.modal').fadeIn('slow');
    });
    $('#modal-btn-id--21.modal-btn').click(function() {
        $('#modal-id--21.modal').fadeIn('slow');
    });
    $('#modal-btn-id--22.modal-btn').click(function() {
        $('#modal-id--22.modal').fadeIn('slow');
    });
    $('#modal-btn-id--23.modal-btn').click(function() {
        $('#modal-id--23.modal').fadeIn('slow');
    });
    $('#modal-btn-id--24.modal-btn').click(function() {
        $('#modal-id--24.modal').fadeIn('slow');
    });
    $('#modal-btn-id--25.modal-btn').click(function() {
        $('#modal-id--25.modal').fadeIn('slow');
    });
    $('#modal-btn-id--26.modal-btn').click(function() {
        $('#modal-id--26.modal').fadeIn('slow');
    });
    $('#modal-btn-id--27.modal-btn').click(function() {
        $('#modal-id--27.modal').fadeIn('slow');
    });
    $('#modal-btn-id--28.modal-btn').click(function() {
        $('#modal-id--28.modal').fadeIn('slow');
    });
    $('#modal-btn-id--29.modal-btn').click(function() {
        $('#modal-id--29.modal').fadeIn('slow');
    });
    $('#modal-btn-id--30.modal-btn').click(function() {
        $('#modal-id--30.modal').fadeIn('slow');
    });
    $('#modal-btn-id--31.modal-btn').click(function() {
        $('#modal-id--31.modal').fadeIn('slow');
    });
    $('#modal-btn-id--32.modal-btn').click(function() {
        $('#modal-id--32.modal').fadeIn('slow');
    });
    $('#modal-btn-id--33.modal-btn').click(function() {
        $('#modal-id--33.modal').fadeIn('slow');
    });
    $('#modal-btn-id--34.modal-btn').click(function() {
        $('#modal-id--34.modal').fadeIn('slow');
    });
    $('#modal-btn-id--35.modal-btn').click(function() {
        $('#modal-id--35.modal').fadeIn('slow');
    });
    $('#modal-btn-id--36.modal-btn').click(function() {
        $('#modal-id--36.modal').fadeIn('slow');
    });
    $('#modal-btn-id--37.modal-btn').click(function() {
        $('#modal-id--37.modal').fadeIn('slow');
    });
    $('#modal-btn-id--38.modal-btn').click(function() {
        $('#modal-id--38.modal').fadeIn('slow');
    });
    $('#modal-btn-id--39.modal-btn').click(function() {
        $('#modal-id--39.modal').fadeIn('slow');
    });
    $('#modal-btn-id--40.modal-btn').click(function() {
        $('#modal-id--40.modal').fadeIn('slow');
    });
    $('#modal-btn-id--41.modal-btn').click(function() {
        $('#modal-id--41.modal').fadeIn('slow');
    });
    $('#modal-btn-id--42.modal-btn').click(function() {
        $('#modal-id--42.modal').fadeIn('slow');
    });
    $('#modal-btn-id--43.modal-btn').click(function() {
        $('#modal-id--43.modal').fadeIn('slow');
    });
    $('#modal-btn-id--44.modal-btn').click(function() {
        $('#modal-id--44.modal').fadeIn('slow');
    });
    $('#modal-btn-id--45.modal-btn').click(function() {
        $('#modal-id--45.modal').fadeIn('slow');
    });
    $('#modal-btn-id--46.modal-btn').click(function() {
        $('#modal-id--46.modal').fadeIn('slow');
    });
    $('#modal-btn-id--47.modal-btn').click(function() {
        $('#modal-id--47.modal').fadeIn('slow');
    });
    $('#modal-btn-id--48.modal-btn').click(function() {
        $('#modal-id--48.modal').fadeIn('slow');
    });
    $('#modal-btn-id--49.modal-btn').click(function() {
        $('#modal-id--49.modal').fadeIn('slow');
    });
    $('#modal-btn-id--50.modal-btn').click(function() {
        $('#modal-id--50.modal').fadeIn('slow');
    });
    $('#modal-btn-id--51.modal-btn').click(function() {
        $('#modal-id--51.modal').fadeIn('slow');
    });
    $('#modal-btn-id--52.modal-btn').click(function() {
        $('#modal-id--52.modal').fadeIn('slow');
    });
    $('#modal-btn-id--53.modal-btn').click(function() {
        $('#modal-id--53.modal').fadeIn('slow');
    });
    $('#modal-btn-id--54.modal-btn').click(function() {
        $('#modal-id--54.modal').fadeIn('slow');
    });
    $('#modal-btn-id--55.modal-btn').click(function() {
        $('#modal-id--55.modal').fadeIn('slow');
    });
    $('#modal-btn-id--56.modal-btn').click(function() {
        $('#modal-id--56.modal').fadeIn('slow');
    });
    $('#modal-btn-id--57.modal-btn').click(function() {
        $('#modal-id--57.modal').fadeIn('slow');
    });
    $('#modal-btn-id--58.modal-btn').click(function() {
        $('#modal-id--58.modal').fadeIn('slow');
    });
    $('#modal-btn-id--59.modal-btn').click(function() {
        $('#modal-id--59.modal').fadeIn('slow');
    });
    $('#modal-btn-id--60.modal-btn').click(function() {
        $('#modal-id--60.modal').fadeIn('slow');
    });
    $('#modal-btn-id--61.modal-btn').click(function() {
        $('#modal-id--61.modal').fadeIn('slow');
    });
    $('#modal-btn-id--62.modal-btn').click(function() {
        $('#modal-id--62.modal').fadeIn('slow');
    });
    $('#modal-btn-id--63.modal-btn').click(function() {
        $('#modal-id--63.modal').fadeIn('slow');
    });
    $('#modal-btn-id--64.modal-btn').click(function() {
        $('#modal-id--64.modal').fadeIn('slow');
    });
    $('#modal-btn-id--65.modal-btn').click(function() {
        $('#modal-id--65.modal').fadeIn('slow');
    });
    $('#modal-btn-id--66.modal-btn').click(function() {
        $('#modal-id--66.modal').fadeIn('slow');
    });
    $('#modal-btn-id--67.modal-btn').click(function() {
        $('#modal-id--67.modal').fadeIn('slow');
    });
    $('#modal-btn-id--68.modal-btn').click(function() {
        $('#modal-id--68.modal').fadeIn('slow');
    });
    $('#modal-btn-id--69.modal-btn').click(function() {
        $('#modal-id--69.modal').fadeIn('slow');
    });
    $('#modal-btn-id--70.modal-btn').click(function() {
        $('#modal-id--70.modal').fadeIn('slow');
    });
    $('#modal-btn-id--71.modal-btn').click(function() {
        $('#modal-id--71.modal').fadeIn('slow');
    });
    $('#modal-btn-id--72.modal-btn').click(function() {
        $('#modal-id--72.modal').fadeIn('slow');
    });
    $('#modal-btn-id--73.modal-btn').click(function() {
        $('#modal-id--73.modal').fadeIn('slow');
    });
    $('#modal-btn-id--74.modal-btn').click(function() {
        $('#modal-id--74.modal').fadeIn('slow');
    });
    $('#modal-btn-id--75.modal-btn').click(function() {
        $('#modal-id--75.modal').fadeIn('slow');
    });
    $('#modal-btn-id--76.modal-btn').click(function() {
        $('#modal-id--76.modal').fadeIn('slow');
    });
    $('#modal-btn-id--77.modal-btn').click(function() {
        $('#modal-id--77.modal').fadeIn('slow');
    });
    $('#modal-btn-id--78.modal-btn').click(function() {
        $('#modal-id--78.modal').fadeIn('slow');
    });
    $('#modal-btn-id--79.modal-btn').click(function() {
        $('#modal-id--79.modal').fadeIn('slow');
    });
    $('#modal-btn-id--80.modal-btn').click(function() {
        $('#modal-id--80.modal').fadeIn('slow');
    });
    $('#modal-btn-id--81.modal-btn').click(function() {
        $('#modal-id--81.modal').fadeIn('slow');
    });
    $('#modal-btn-id--82.modal-btn').click(function() {
        $('#modal-id--82.modal').fadeIn('slow');
    });
    $('#modal-btn-id--83.modal-btn').click(function() {
        $('#modal-id--83.modal').fadeIn('slow');
    });
    $('#modal-btn-id--84.modal-btn').click(function() {
        $('#modal-id--84.modal').fadeIn('slow');
    });
    $('#modal-btn-id--85.modal-btn').click(function() {
        $('#modal-id--85.modal').fadeIn('slow');
    });
    $('#modal-btn-id--86.modal-btn').click(function() {
        $('#modal-id--86.modal').fadeIn('slow');
    });
    $('#modal-btn-id--87.modal-btn').click(function() {
        $('#modal-id--87.modal').fadeIn('slow');
    });
    $('#modal-btn-id--88.modal-btn').click(function() {
        $('#modal-id--88.modal').fadeIn('slow');
    });
    $('#modal-btn-id--89.modal-btn').click(function() {
        $('#modal-id--89.modal').fadeIn('slow');
    });
    $('#modal-btn-id--90.modal-btn').click(function() {
        $('#modal-id--90.modal').fadeIn('slow');
    });
    $('#modal-btn-id--91.modal-btn').click(function() {
        $('#modal-id--91.modal').fadeIn('slow');
    });
    $('#modal-btn-id--92.modal-btn').click(function() {
        $('#modal-id--92.modal').fadeIn('slow');
    });
    $('#modal-btn-id--93.modal-btn').click(function() {
        $('#modal-id--93.modal').fadeIn('slow');
    });
    $('#modal-btn-id--94.modal-btn').click(function() {
        $('#modal-id--94.modal').fadeIn('slow');
    });
    $('#modal-btn-id--95.modal-btn').click(function() {
        $('#modal-id--95.modal').fadeIn('slow');
    });
    $('#modal-btn-id--96.modal-btn').click(function() {
        $('#modal-id--96.modal').fadeIn('slow');
    });
    $('#modal-btn-id--97.modal-btn').click(function() {
        $('#modal-id--97.modal').fadeIn('slow');
    });
    $('#modal-btn-id--98.modal-btn').click(function() {
        $('#modal-id--98.modal').fadeIn('slow');
    });
    $('#modal-btn-id--99.modal-btn').click(function() {
        $('#modal-id--99.modal').fadeIn('slow');
    });
    $('#modal-btn-id--100.modal-btn').click(function() {
        $('#modal-id--100.modal').fadeIn('slow');
    });
    $('#modal-btn-id--101.modal-btn').click(function() {
        $('#modal-id--101.modal').fadeIn('slow');
    });
    $('#modal-btn-id--102.modal-btn').click(function() {
        $('#modal-id--102.modal').fadeIn('slow');
    });
    $('#modal-btn-id--103.modal-btn').click(function() {
        $('#modal-id--103.modal').fadeIn('slow');
    });
    $('#modal-btn-id--104.modal-btn').click(function() {
        $('#modal-id--104.modal').fadeIn('slow');
    });
    $('#modal-btn-id--105.modal-btn').click(function() {
        $('#modal-id--105.modal').fadeIn('slow');
    });
    $('#modal-btn-id--106.modal-btn').click(function() {
        $('#modal-id--106.modal').fadeIn('slow');
    });
    $('#modal-btn-id--107.modal-btn').click(function() {
        $('#modal-id--107.modal').fadeIn('slow');
    });
    $('#modal-btn-id--108.modal-btn').click(function() {
        $('#modal-id--108.modal').fadeIn('slow');
    });
    $('#modal-btn-id--109.modal-btn').click(function() {
        $('#modal-id--109.modal').fadeIn('slow');
    });
    $('#modal-btn-id--110.modal-btn').click(function() {
        $('#modal-id--110.modal').fadeIn('slow');
    });
    $('#modal-btn-id--111.modal-btn').click(function() {
        $('#modal-id--111.modal').fadeIn('slow');
    });
    $('#modal-btn-id--112.modal-btn').click(function() {
        $('#modal-id--112.modal').fadeIn('slow');
    });
    $('#modal-btn-id--113.modal-btn').click(function() {
        $('#modal-id--113.modal').fadeIn('slow');
    });
    $('#modal-btn-id--114.modal-btn').click(function() {
        $('#modal-id--114.modal').fadeIn('slow');
    });
    $('#modal-btn-id--115.modal-btn').click(function() {
        $('#modal-id--115.modal').fadeIn('slow');
    });
    $('#modal-btn-id--116.modal-btn').click(function() {
        $('#modal-id--116.modal').fadeIn('slow');
    });
    $('#modal-btn-id--117.modal-btn').click(function() {
        $('#modal-id--117.modal').fadeIn('slow');
    });
    $('#modal-btn-id--118.modal-btn').click(function() {
        $('#modal-id--118.modal').fadeIn('slow');
    });
    $('#modal-btn-id--119.modal-btn').click(function() {
        $('#modal-id--119.modal').fadeIn('slow');
    });
    $('#modal-btn-id--120.modal-btn').click(function() {
        $('#modal-id--120.modal').fadeIn('slow');
    });
    $('#modal-btn-id--121.modal-btn').click(function() {
        $('#modal-id--121.modal').fadeIn('slow');
    });
    $('#modal-btn-id--122.modal-btn').click(function() {
        $('#modal-id--122.modal').fadeIn('slow');
    });
    $('#modal-btn-id--123.modal-btn').click(function() {
        $('#modal-id--123.modal').fadeIn('slow');
    });
    $('#modal-btn-id--124.modal-btn').click(function() {
        $('#modal-id--124.modal').fadeIn('slow');
    });
    $('#modal-btn-id--125.modal-btn').click(function() {
        $('#modal-id--125.modal').fadeIn('slow');
    });
    $('#modal-btn-id--126.modal-btn').click(function() {
        $('#modal-id--126.modal').fadeIn('slow');
    });
    $('#modal-btn-id--127.modal-btn').click(function() {
        $('#modal-id--127.modal').fadeIn('slow');
    });
    $('#modal-btn-id--128.modal-btn').click(function() {
        $('#modal-id--128.modal').fadeIn('slow');
    });
    $('#modal-btn-id--129.modal-btn').click(function() {
        $('#modal-id--129.modal').fadeIn('slow');
    });
    $('#modal-btn-id--130.modal-btn').click(function() {
        $('#modal-id--130.modal').fadeIn('slow');
    });
    $('#modal-btn-id--131.modal-btn').click(function() {
        $('#modal-id--131.modal').fadeIn('slow');
    });
    $('#modal-btn-id--132.modal-btn').click(function() {
        $('#modal-id--132.modal').fadeIn('slow');
    });
    $('#modal-btn-id--133.modal-btn').click(function() {
        $('#modal-id--133.modal').fadeIn('slow');
    });
    $('#modal-btn-id--134.modal-btn').click(function() {
        $('#modal-id--134.modal').fadeIn('slow');
    });
    $('#modal-btn-id--135.modal-btn').click(function() {
        $('#modal-id--135.modal').fadeIn('slow');
    });
    $('#modal-btn-id--136.modal-btn').click(function() {
        $('#modal-id--136.modal').fadeIn('slow');
    });
    $('#modal-btn-id--137.modal-btn').click(function() {
        $('#modal-id--137.modal').fadeIn('slow');
    });
    $('#modal-btn-id--138.modal-btn').click(function() {
        $('#modal-id--138.modal').fadeIn('slow');
    });
    $('#modal-btn-id--139.modal-btn').click(function() {
        $('#modal-id--139.modal').fadeIn('slow');
    });
    $('#modal-btn-id--140.modal-btn').click(function() {
        $('#modal-id--140.modal').fadeIn('slow');
    });
    $('#modal-btn-id--141.modal-btn').click(function() {
        $('#modal-id--141.modal').fadeIn('slow');
    });
    $('#modal-btn-id--142.modal-btn').click(function() {
        $('#modal-id--142.modal').fadeIn('slow');
    });
    $('#modal-btn-id--143.modal-btn').click(function() {
        $('#modal-id--143.modal').fadeIn('slow');
    });
    $('#modal-btn-id--144.modal-btn').click(function() {
        $('#modal-id--144.modal').fadeIn('slow');
    });
    $('#modal-btn-id--145.modal-btn').click(function() {
        $('#modal-id--145.modal').fadeIn('slow');
    });
    $('#modal-btn-id--146.modal-btn').click(function() {
        $('#modal-id--146.modal').fadeIn('slow');
    });
    $('#modal-btn-id--147.modal-btn').click(function() {
        $('#modal-id--147.modal').fadeIn('slow');
    });
    $('#modal-btn-id--148.modal-btn').click(function() {
        $('#modal-id--148.modal').fadeIn('slow');
    });
    $('#modal-btn-id--149.modal-btn').click(function() {
        $('#modal-id--149.modal').fadeIn('slow');
    });

    $('.close-btn').click(function() {
        $('.modal').fadeOut('slow');
    });

});

$(function() { Page.init(); });